<template lang="html">
    <div class="wrapper">
        <div class="profile-header-block">
            <div class="profile-header-block__body">
                <div class="profile-container">
                    <div class="profile-title">
                        Personal Information
                    </div>
                    <div class="profile-content">
                        <div class="profile-content__block">
                            <!-- account name -->
                            <div class="profile-header-block__inner-row">
                                <div class="profile-header-block__name">
                                    <p class="profile-header-block--label">
                                        Account name:
                                    </p>
                                    <p class="profile-header-block--value profile-header-block--value_username">
                                        {{ setting.firstname }} {{ setting.lastname }}
                                    </p>
                                </div>
                            </div>
                            <!-- account name -->
                        </div>
                    </div>
                </div>
                <div class="profile-container">
                    <div class="profile-title">
                        Contact Information
                    </div>
                    <div class="profile-content">
                        <div class="profile-content__block">
                            <!-- email -->
                            <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Email:
                                    </p>
                                    <p class="profile-header-block--value">
                                        {{ setting.email }}
                                    </p>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_email' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!-- email -->
                        </div>
                        <div class="profile-content__block">
                            <!-- phone -->
                            <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Phone:
                                    </p>
                                    <p class="profile-header-block--value">
                                        <span v-if="setting.phone.phone_code && setting.phone.phone">
                                            +{{ setting.phone.phone_code }} {{ setting.phone.phone }}
                                        </span>
                                        <span v-else>
                                            Not provided
                                        </span>
                                    </p>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_phone' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!-- phone -->
                        </div>
                        <div class="profile-content__block">
                            <!-- address -->
                            <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Address:
                                    </p>
                                    <AccountSettingsAddress
                                        v-if="clientAddress"
                                        :address="setting.address"
                                        class="profile-header-block--value"
                                    />
                                    <span
                                        v-else
                                        class="profile-header-block--value"
                                    >
                                        Not provided
                                    </span>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_address' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!-- address -->
                        </div>
                    </div>
                </div>
                <div class="profile-container">
                    <div class="profile-title">
                        Security
                    </div>
                    <div class="profile-content">
                        <div class="profile-content__block">
                            <!-- password -->
                            <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Password:
                                    </p>
                                    <p class="profile-header-block--value">
                                        **********
                                    </p>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_password' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!-- password -->
                        </div>
                        <div class="profile-content__block">
                            <!-- 2FA -->
                            <!-- <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Two-Factor Authentication:
                                    </p>
                                    <p class="profile-header-block--value">
                                        {{ getter2FA ? 'Enabled' : 'Disabled' }}
                                    </p>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_security' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div> -->
                            <!-- 2FA -->
                        </div>
                    </div>
                </div>
                <div class="profile-container">
                    <div class="profile-title">
                        Advanced Settings
                    </div>
                    <div class="profile-content">
                        <div class="profile-content__block">
                            <!-- writer preference -->
                            <div class="profile-header-block__inner-row">
                                <div>
                                    <p class="profile-header-block--label">
                                        Preferred writers:
                                    </p>
                                    <p class="profile-header-block--value">
                                        <span>{{ (setting.preferred_writers && setting.preferred_writers.length) || 0 }} preferred </span>
                                        <span> {{ (setting.blocked_writers && setting.blocked_writers.length) || 0 }} blocked</span>
                                    </p>
                                </div>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_writers' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!-- writer preference -->
                        </div>
                        <div class="profile-content__block">
                            <!-- notifications -->
                            <div class="profile-header-block__inner-row">
                                <p class="profile-header-block--label">
                                    Notifications
                                </p>
                                <custom-button
                                    class="profile-header-block--edit"
                                    small
                                    hover
                                    :width="'50px'"
                                    :height="'28px'"
                                    @on-btn-click="$router.push({ name: 'profile_notifications' })"
                                >
                                    <pencil />
                                </custom-button>
                            </div>
                            <!--  -->
                            <!-- notifications -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pencil from 'mdi-vue/Pencil'
import AccountSettingsAddress from '@/components/account-settings/AccountSettingsAddress'
import { createNamespacedHelpers, mapGetters } from 'vuex';

import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        Pencil,
        AccountSettingsAddress
    },

    metaInfo: {
        title: 'Settings'
    },
    computed: {
        ...mapGetters('client', {
            setting: 'getterSetting'
        }),
        clientAddress() {
            return this.setting.address
        }
    },
    async created() {
        await this.updateClient()
    },
    methods: {
        ...mapClientActions({
            updateClient: ACTION_UPDATE_CLIENT_DATA
        })
    }
}
</script>

<style lang="scss">
.profile-header-block--value.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    max-width: 200px;
}
.phonetic {
    margin-right: 4px;
}
</style>
